import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Devices from "@material-ui/icons/Devices";
import Place from "@material-ui/icons/Place";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Let{"'"}s talk product</h2>
          <h5 className={classes.description}>
            We provide a golf cart and/or conversion kits to get you up and running.  You can choose
            to invest as little or as much as you want.  Our application allows people to start renting
            your cart hassle free.  With all this we also provide you with a dashboard to track your cart, users, and profits.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Cart Technology"
              description="Get one of our carts free of charge so that you can start renting immediately, or use one of our conversion kits for more profit."
              icon={Devices}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Location"
              description="We provide the technology and all you need to do is provide the location."
              icon={Place}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Profit"
              description="We share the profits, and you decide how much. With our dashboard you can monitor your success. We'll provide you with everything you need to succeed"
              icon={MonetizationOn}
              iconColor="success"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
